* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  background-color: rgb(252, 252, 252);
  text-rendering: optimizeLegibility;
}

.row {
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}
