.jumbotron {
  height: 100vh;

  background-image: linear-gradient(
      rgba(255, 255, 255, 0.137),
      rgba(255, 255, 255, 0.137)
    ),
    url("PaintBrushMan.jpg");

  background-size: cover;
  background-position: center;
  color: #083358;
  font-family: "Oswald", sans-serif;
  padding-top: 0px;
  border-bottom: solid 3px #fc3c3c;
  position: relative;
  margin-bottom: 0px;
  overflow: hidden;
  background-color: rgb(252, 252, 252);
}

.jumbo-subtext {
  position: relative;
  left: 6%;
  width: 40%;
  text-align: center;
  line-height: 180%;
  font-size: 210%;
  font-weight: 300;
  font-family: "Oswald", sans-serif;
  color: #083358;
  margin-top: 11%;
  height: 100%;
}

.btn:link,
.btn:visited {
  display: block;
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
  margin-top: 6%;
  background-color: #fff;
  color: #083358 !important;
  font-size: 18px;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  border-color: #fc3c3c;
  border-radius: 200px;
  border: 2px solid #fc3c3c;
  transition: background-color 0.4s, border 0.4s, color 0.4s;
}

.btn:hover {
  background-color: #fc3c3c;
  color: #fff !important;
  transition: background-color 0.4s, border 0.4s, color 0.4s;
}

mark {
  color: #fc3c3c !important;
  background-color: rgb(253, 253, 253);
}

@media (max-device-width: 700px) {
  .jumbo-subtext {
    font-size: 180%;
    left: 15%;
  }
}

@media (max-device-width: 545px) {
  .jumbo-subtext {
    font-size: 140%;
    left: 15%;
    margin-top: 20%;
  }
}

@media (max-device-width: 400px) {
  .jumbo-subtext {
    font-size: 125%;
    left: 12%;
    margin-top: 25%;
    width: 65%;
  }

  .btn:link,
  .btn:visited {
    font-size: 15px;
  }

  .navbar-toggler {
    margin-left: auto;
    margin-right: auto;
  }
}
