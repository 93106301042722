.builder-header {
  font-size: 55px;
  color: #083358;
  font-family: "Oswald", sans-serif;
  margin-top: 30px;
}

.builder-break {
  width: 200px;
  border: 2px solid #083358;
  color: #083358;
  margin-bottom: 60px;
  margin-right: auto;
  margin-left: auto;
}

.builders {
  text-align: center;
  height: fit-content;
  background-color: #fff;
  border-bottom: solid 3px;
  border-color: #fc3c3c;
  padding-top: 50px;
  padding-bottom: 100px;
}

img {
  margin: 20px 5px 25px 5px;
}

@media (max-device-width: 545px) {
  .builder-image {
    height: 120px;
    width: 120px;
  }
}
