.navbar {
  height: fit-content;
  background-color: rgb(252, 252, 252);
  border-bottom: solid 1px #fc3c3c;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-left: 5%;
  margin-right: 5%;
}
.navbar-brand {
  color: #083358 !important;
  font-family: "Oswald", sans-serif;
  font-size: 225% !important;
  margin-left: 5%;
}
.navbar-text a {
  font-family: "Oswald", sans-serif;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 20px;
  color: #083358 !important;
}
.fas.fa-paint-roller {
  color: #083358 !important;
  margin-right: 20px;
}

.nav-link:link,
.nav-link:visited {
  color: #083358 !important;

  transition: color 0.4s;
}

.nav-link:hover {
  color: #fc3c3c !important;
  font-size: 20px;
  text-decoration: none;
  transition: color 0.4s;
  cursor: pointer;
}

.justify-content-end {
  margin-right: 5%;
}

@media (max-device-width: 545px) {
  .navbar {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .navbar-brand {
    font-size: 180% !important;
    margin-left: 5%;
  }
}

@media (max-device-width: 400px) {
  .navbar {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .navbar-brand {
    font-size: 180% !important;
    margin-left: 5%;
  }
}
