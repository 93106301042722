.row-services {
  color: #fff;
  background-color: #083358;
  text-align: center;
  height: fit-content;
  border-bottom: solid 3px;
  border-color: #083358;
  padding-top: 50px;

  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  letter-spacing: 1px;
}

.services-header {
  font-size: 55px;
  font-weight: bold;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
}
hr {
  display: block !important;
}
.services-break {
  width: 25%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 45px;
  border: 2px solid #fff;
}

.service-box {
  margin: 20px 20px 20px 20px;
  text-align: center;
  color: white;
}

.description {
  font-size: 18px;
  line-height: 175%;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

.desc-header {
  font-family: "Oswald", sans-serif;
}

@media (max-width: 700px) {
  .col {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 500px) {
  .col {
    padding-left: 40px;
    padding-right: 40px;
  }
}
