.contact {
  height: fit-content;
  background-color: #083358;
  padding-top: 110px;
  padding-bottom: 80px;
  padding-left: 5%;
  padding-right: 5%;
  overflow: auto;
}

.btn-submit {
  display: block;
  width: 100px;
  margin-right: auto;
  margin-top: 5%;
  background-color: #fff;
  color: #083358 !important;
  font-size: 25px;
  font-family: "Oswald", sans-serif;
  font-weight: 500;
  border-color: #fc3c3c;
  border-radius: 20px;
  transition: background-color 0.4s, border 0.4s, color 0.4s;
}

.btn-submit:hover {
  background-color: #fc3c3c;
  color: #fff !important;
  transition: background-color 0.4s, border 0.4s, color 0.4s;
}

label {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 25px;
  letter-spacing: 0.5px;
}

.contact-header {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-size: 40px;
  text-align: center;
  letter-spacing: 0.5px;
}

#contact-header-right {
  text-align: right;
  margin-bottom: 50px;
}

.contact-break {
  background-color: #fff;
  width: 300px;
  margin-right: auto;
  margin-left: auto;
}

.contact-address {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: right;
  font-size: 25px;
}

.contact-info {
  color: #fff;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: right;
  font-size: 25px;
  margin-top: 100px;
}

.contact-email {
  color: #fc3c3c;
  font-family: "Oswald", sans-serif;
  font-weight: 300;
  text-align: right;
  font-size: 25px;
  margin-top: 100px;
  line-height: 150%;
}

a:link,
a:visited {
  color: #fc3c3c !important;
  font-size: 25px;
  transition: color 0.4s;
}

a:hover {
  color: #cf0303 !important;
  font-size: 25px;
  transition: color 0.4s;
  text-decoration: underline;
}

@media (max-device-width: 700px) {
  .contact {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 75px;
    padding-bottom: 75px;
  }

  .contact-break {
    margin-bottom: 50px;
  }

  .btn-submit {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .contact-info {
    text-align: center;
    font-size: 18px;
  }

  .contact-email {
    text-align: center;
    font-size: 18px;
  }

  #contact-header-right {
    text-align: center;
    margin-top: 40px;
  }

  .contact-address {
    text-align: center;
    font-size: 18px;
  }
}
